import React, {FC, useCallback, useState} from 'react';

import './header.css'
import './header-media.css'

import {useTranslation} from "react-i18next";
import useScrollspy from "../../hook/useScrollspy";
import {useNavigate} from "react-router-dom";
import {login_href, support_href} from "../../data/constants";
import TermsLink from "../TermsLink";
import {ITerms} from "../../pages/HomePage/HomePage";

const menus = [
  {id: 'section-1', title: 'MENU_1'},
  {id: 'section-2', title: 'MENU_2'},
  {id: 'section-3', title: 'MENU_3'},
]

interface Props {
  hideNav?: boolean
  terms?: ITerms
}

const Header: FC<Props> = ({hideNav, terms}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const activeId = useScrollspy(['section-1', 'section-2', 'section-3'], 200);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  const toggleMenu = () => {
    setMenuVisible(prevState => !prevState)
  }

  const handleClick = (id: string) => (e: any) => {
    e.preventDefault();
    setMenuVisible(false);
    const element: any = document.getElementById(id);
    const offset = 100;
    const bodyRect: any = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {!hideNav &&
      <div className={`hidden-menu${menuVisible ? ' visible' : ''}`}>
        <nav>
          <ul className='ps-0'>
            {menus.map((item, i) => {
              return <li key={i}>
                <a
                  href={`#${item.id}`}
                  className={`${item.id === activeId ? 'active' : ''}`}
                  onClick={handleClick(item.id)}
                >
                  {t(item.title)}
                </a>
              </li>
            })}
          </ul>
        </nav>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column'>
            <TermsLink type='privacy' doc={terms?.privacy} className='mb-2' />
            <TermsLink type='terms' doc={terms?.terms}/>
            {/*<a href={privacy_href} target='_blank' className='text-14 text-decoration-underline mb-2'>{t('PRIVACY')}</a>*/}
            {/*<a href={terms_href} target='_blank' className='text-14 text-decoration-underline'>{t('TERMS')}</a>*/}
          </div>
          <a href={`mailto:${support_href}`} target='_blank' className='btn btn-outline-secondary'>
            <div>{t('CONTACT_US')}</div>
          </a>
        </div>
      </div>
      }
      <header>
        <div className="header container">
          <div className="menu-burger" onClick={toggleMenu}/>
          {!hideNav
            ?
            <div className='header-menu'>
              <nav>
                <ul className='ps-0'>
                  {menus.map((item, i) => {
                    return <li key={i}>
                      <a
                        href={`#${item.id}`}
                        className={`${item.id === activeId ? 'active' : ''}`}
                        onClick={handleClick(item.id)}
                      >
                        {t(item.title)}
                      </a>
                    </li>
                  })}
                </ul>
              </nav>
            </div>
            :<div />
          }
          <div className='header-right'>
            <a href={login_href} target={'_blank'} className='btn btn-outline-secondary'>
              {t('LOGIN')}
            </a>
            {!hideNav
            ?
              <button className='btn btn-primary' type='button' onClick={handleRegister}>
                {t('ADD_CHANNEL')}
              </button>
            :
              <a href={`mailto:${support_href}`} target='_blank' className='btn btn-outline-primary order-1 order-xl-0'>
                <div>
                  <span className='d-none d-sm-inline'>{t('WRITE_TO_SUPPORT')}</span>
                  <span className='d-sm-none d-inline'>{t('SUPPORT')}</span>
                </div>
              </a>
            }

          </div>
        </div>
      </header>

    </>
  );
}

export default Header;