import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Modal } from 'react-responsive-modal';
import {IDoc} from "../utils/rest";
import {ReactSVG} from "react-svg";
import close  from '../assets/images/icons/close.svg';

interface Props {
  type: 'terms'|'privacy';
  className?: string;
  doc?: IDoc
}

const TermsLink: FC<Props> = ({className, type, doc}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <div className='terms-link' onClick={onOpenModal}>{t(type.toUpperCase())}</div>
      {open &&
        <Modal open onClose={onCloseModal} showCloseIcon={false} classNames={{modal: 'terms-modal'}}>
          <div className='d-flex justify-content-between'>
            <h3>{doc?.title}</h3>
            <ReactSVG src={close} onClick={onCloseModal} className='react-icon cur-pointer' />
          </div>
          <div dangerouslySetInnerHTML={{__html: doc?.content || ''}} />
        </Modal>
      }
    </>
  );
}

export default TermsLink;