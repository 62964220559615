import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import OutsideClick from "../OutsideClick";
import {ReactSVG} from "react-svg";
import caret from '../../assets/images/icons/caret.svg';
import {availableLanguages} from "../../i18n";

interface Props {
  className?: string;
}


const LangSelect: FC<Props> = ({className}) => {
  const {i18n} = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleChangeLanguage = (ln: string) => (e: any) => {
    e.stopPropagation();
    localStorage.setItem('ln', ln);
    i18n.changeLanguage(ln);
    setVisible(false)
  }

  const currentLang = i18n.language;

  if (availableLanguages.length <= 1) return null;

  return (
    <OutsideClick outsideClickEvent={() => setVisible(false)}
                  className={`header-lang-select${visible ? ' visible' : ''} ${className || ''}`}>
      <div className="header-lang-select-value" onClick={() => setVisible(true)}>
        <span>{currentLang}</span>
        <ReactSVG src={caret} className='header-lang-select-arrow react-icon'/>
      </div>
      <div className="header-lang-select-hidden">
        {availableLanguages.map(ln => {
          if (ln === currentLang) return null;
          return (
            <div className="header-lang-select-hidden-item" onClick={handleChangeLanguage(ln)} key={ln}>
              {ln}
            </div>
          )
        })}
      </div>
    </OutsideClick>
  );
}

export default LangSelect;