import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow_svg from './assets/arrow-right.svg';
import btn_plus from '../../../assets/images/icons/btn_plus.svg';

import './assets/styles.css'
import Logo from "../../../components/Logo";

interface Props {
  onRegister: () => void;
}

const Section1: FC<Props> = ({onRegister}) => {
  const {t} = useTranslation();
  return (
    <section className='section-1' id='section-1'>
      <div className='section-1-logo'><Logo/></div>
      <div className='position-relative'>
        <div className="circle-bg-red"/>
        <div className="circle-bg-yellow"/>
        <h1 dangerouslySetInnerHTML={{__html: t('SECTION1_TITLE') || ''}}/>
      </div>
      <p className='section-1-text'>{t('SECTION1_TEXT')}</p>
      <button type={'button'} className='btn btn-primary btn-lg section-1-btn' onClick={onRegister}>
        <span className='flex-grow-1 text-center'>{t('ADD_CHANNEL')}</span>
        <ReactSVG src={btn_plus} className='react-icon btn-primary-icon'/>
      </button>
      <div className="section-1-footer-wrap">
        <div className="section-1-footer-bg white" />
        <div className="section-1-footer-bg yellow" />
        <div className="section-1-footer-bg red" />
        <div className="section-1-footer">
          <div className='section-1-footer-item' dangerouslySetInnerHTML={{__html: t('SECTION_1_TITLE_1') || ''}}/>
          <div className='section-1-footer-separator'/>
          <div className='section-1-footer-item' dangerouslySetInnerHTML={{__html: t('SECTION_1_TITLE_2') || ''}}/>
        </div>
      </div>
    </section>
  );
}

export default Section1;