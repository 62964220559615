import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import screen_1 from './assets/images/screen_1.png';
import screen_2 from './assets/images/screen_2.png';
import screen_3 from './assets/images/screen_3.png';
import screen_4 from './assets/images/screen_4.png';
import {ReactSVG} from "react-svg";
import btn_plus from "../../../assets/images/icons/btn_plus.svg";

const screens = [screen_4, screen_3, screen_2, screen_1];

interface Props {
  onRegister: () => void;
}

const Section3: FC<Props> = ({onRegister}) => {
  const {t} = useTranslation();



  return (
    <section className='section-3' id='section-3'>
      <h2 className='text-center' style={{maxWidth: 928}}
          dangerouslySetInnerHTML={{__html: t('SECTION3_TITLE') || ''}}/>
      <div className='d-flex flex-column-reverse position-relative' style={{zIndex: 1}}>
        {screens.map((item, i) => (
          <div key={i} className={`section-3-item`}>
            <div className="section-3-item-screen">
              <img src={item} alt="screenshot"/>
            </div>
            <div className="section-3-item-separator-wrap">
              <div className="section-3-item-separator"/>
            </div>
            <div className="section-3-item-text">
              <div className="section-3-item-text-content">
                <h3>{t(`SECTION2_${4 - i}_TITLE`)}</h3>
                <p className='text-muted mt-3'>{t(`SECTION2_${4 - i}_TEXT`)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`section-3-item section-3-item-footer`}>
        <div className="section-3-item-footer-line" />
        <div className={`section-3-item-footer-content`}>
          <div className="section-3-item-footer-bg" />
          <h3 className='text-tint mb-0 flex-grow-1 ps-4'>{t('SECTION3_TEXT')}</h3>
          <button style={{minWidth: 294}} type={'button'} className='btn btn-primary btn-lg' onClick={onRegister}>
            <span className='flex-grow-1 text-center'>{t('ADD_CHANNEL')}</span>
            <ReactSVG src={btn_plus} className='react-icon btn-primary-icon'/>
          </button>
        </div>
      </div>

    </section>
  );
}

export default Section3;