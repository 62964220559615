import React, {FC, useState} from 'react';
import Section2Video from "./Section2Video";

interface Props {
  item: any;
  onClose: () => void;
}

const Section2VideoModal: FC<Props> = ({item, onClose}) => {
  const [playing, setPlaying] = useState(true);

  const toggleVideoPlay = () => {
    setPlaying(prevState => !prevState)
  }

  return (
    <Section2Video
      {...item}
      playing={playing}
      onPlay={toggleVideoPlay}
      onExpand={onClose}
      collapse
    />
  );
}

export default Section2VideoModal;