import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import App from './App';
import './assets/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './assets/css/constants.css';
import './assets/css/fonts.css';
import './assets/css/main.css';
import './assets/css/media.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import {ReactSVG} from "react-svg";
import close_svg from './assets/images/close.svg';
import danger_svg from './assets/images/danger.svg';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <App/>
    <ToastContainer
      icon={<ReactSVG src={danger_svg}/>}
      closeButton={<ReactSVG src={close_svg}/>}
    />
  </Router>
);
