import React, {FC, useRef, useState} from 'react';
import './assets/styles.css';
import {useTranslation} from "react-i18next";
import image1 from './assets/image1.jpg';
import image2 from './assets/image2.jpg';
import video_1 from './assets/video_1.mp4';
import video_2 from './assets/video_2.mp4';
import {ReactSVG} from "react-svg";
import arrow_next from "../../../assets/images/icons/arrow-watch.svg";
import expand from "../../../assets/images/icons/expand.svg";
import play_svg from "../../../assets/images/icons/play.svg";
import pause_svg from "../../../assets/images/icons/pause.svg";
import ReactPlayer from 'react-player'
import {Modal} from "react-responsive-modal";
import close from "../../../assets/images/icons/close.svg";
import Section2Video from "./components/Section2Video";
import Section2VideoModal from "./components/Section2VideoModal";


const data = [

  {image: image2, name: 'Gabriela Reacts', link: 'https://www.youtube.com/@Gabyreact', video: video_1},
  {image: image1, name: 'Reactions with Sara', link: 'https://www.youtube.com/@ReactionwithSara', video: video_2},
]

interface Props {
  onRegister: () => void;
}

const Section2: FC<Props> = ({onRegister}) => {
  const {t} = useTranslation();
  const _video: any = useRef();
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [activeVideoIdx, setActiveVideoIdx] = useState<null|number>();

  const toggleVideoPlay = (idx: number) => () => {
    setActiveVideoIdx(prevState => {
      if (prevState === idx) return null;
      return idx;
    })
  }

  const handleExpand = () => {
    setModalVisible(true);
  }

  return (
    <section className='section-2' id='section-2'>
      <h2 dangerouslySetInnerHTML={{__html: t('SECTION2_TITLE') || ''}}/>
      <div className='d-flex flex-column flex-lg-row'>
        {data.map((item, i) => (
          <div className={`section-2-item-wrap`} key={i}>
            <div className='section-2-item'>
              {/*<a href={item.link} target='_blank' className="section-2-item-channel">*/}
              {/*  <div className='section-2-item-channel-logo'>*/}
              {/*    <img src={item.image} alt="channel"/>*/}
              {/*  </div>*/}
              {/*  <div className='section-2-item-channel-name'>*/}
              {/*    <h4>{item.name}</h4>*/}
              {/*    <div className='d-flex align-items-center text-16 mt-1 mt-sm-3'>*/}
              {/*      <span className='text-tint pe-2'>{t('WATCH')}</span>*/}
              {/*      <ReactSVG src={arrow_next} className='react-icon'/>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</a>*/}
              <Section2Video
                {...item}
                playing={i === activeVideoIdx}
                onPlay={toggleVideoPlay(i)}
                onExpand={() => {
                  setActiveVideoIdx(null)
                  setModalVisible(item)
                }}
              />

              <div className='section-2-item-text'>
                <h4>{t(`SECTION2_TITLE_${i + 1}`)}</h4>
                <p className='text-muted mt-3'>{t(`SECTION2_TEXT_${i + 1}`)}</p>
                <div className='text-tint cur-pointer mt-3' onClick={onRegister}>{t('REGISTRATION')}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal center open={Boolean(modalVisible)} onClose={() => setModalVisible(false)} showCloseIcon={false}
             classNames={{modal: 'video-modal'}}>
        <div className='d-flex justify-content-center'>
          {modalVisible
            ?
            <Section2VideoModal item={modalVisible} onClose={() => setModalVisible(false)}/>
            :
            null
          }
        </div>
      </Modal>
    </section>
  );
}

export default Section2;