import React, {FC, useCallback, useState} from 'react';
import {API} from '../../utils/api';
import {toast} from '../../utils/utils';
import {useTranslation} from "react-i18next";
import {Input, Textarea} from "../../components/FormControls";
import {EService, IMonitoringCategory, IRegisterRequest,} from "../../utils/rest";
import {ReactSVG} from "react-svg";
import arrow from "../../assets/images/icons/btn_plus.svg";
import users_svg from './icons/users.svg';
import youtube_svg from './icons/youtube.svg';
import smile_svg from './icons/smile.svg';
import Select, {components} from 'react-select'
import {Regions} from "../../utils/directory";
import geos from '../../translates/geos.json';
import Loadable from "../../components/Loadable";
import Button from "../../components/button";
import {login_href} from "../../data/constants";

interface Props {
  setSuccessForm: (status: boolean) => void;
  categories?: IMonitoringCategory[];
}


const RegistrationForm: FC<Props> = ({setSuccessForm, categories}) => {
  const {t} = useTranslation();
  const [userToken, setToken] = useState('');
  const [user, setUser] = useState<IRegisterRequest>({email: '', password: ''});
  const [channel, setChannel] = useState<{ url: string, categories: any[], countries: any[] }>({
    url: '',
    categories: [],
    countries: []
  });
  const [loading, setLoading] = useState(false);

  const handleChangeUser = useCallback((key: string) => (e: any) => {
    setUser(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleChangeChannel = (key: string, event?: boolean) => (e: any) => {
    const value = event ? e.target.value : e;
    setChannel(prevState => ({...prevState, [key]: value}));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return null;
    setLoading(true);
    try {
      let token = userToken;
      if (!token) {
        const res = await API.Users.register({
          ...user,
          service: EService.Reactrino,
          source: window.localStorage.getItem('source'),
          ref: window.localStorage.getItem('ref'),
        });
        setToken(res.token);
        token = res.token;
      }
      if (token) {
        const categories = channel.categories.map(c => c.id);
        const countries = channel.countries.map(c => c.value);
        API.setToken(token);
        await API.ReactorChannels.addChannel({...channel, categories, countries});
        // @ts-ignore
        ym(96544769,'reachGoal','submit');
        window.location.href = `${login_href}as/${token}`
      }


//eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9

      // window.ym(91359429, 'reachGoal', 'reg');
      // setSuccessForm(true);
    } catch (e: any) {
      toast(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="registration" onSubmit={handleSubmit}>
      <h2 className='mt-3 mb-0'>{t('REGISTRATION')}</h2>
      <div className="registration-separator"/>
      <div className='mb-4'>
        <div className='text-bold text-tint'>{t('FILL_REGISTRATION')}</div>
        {[users_svg, youtube_svg, smile_svg].map((item, i) => (
          <div className='mt-2 d-flex align-items-center' key={i}>
            <ReactSVG src={item} className='react-icon' style={{opacity: 0.5}}/>
            <div className={'ps-2'}>{t(`REGISTRATION_REQ_${i + 1}`)}</div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Input
            required
            type='email'
            label={t('EMAIL') || ''}
            placeholder={t('EMAIL_PLACEHOLDER') || ''}
            value={user.email}
            onChange={handleChangeUser('email')}
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            type='password'
            required
            label={t('PASSWORD') || ''}
            placeholder={t('PASSWORD_PLACEHOLDER') || ''}
            value={user.password}
            onChange={handleChangeUser('password')}
          />
        </div>
      </div>
      <div className='row'>
        <div className="col-12 col-lg-8">
          <Input
            required
            label='URL_PLACEHOLDER'
            placeholder={t('LINK') || ''}
            className='sm'
            value={channel.url}
            onChange={handleChangeChannel('url', true)}
          />
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col-12 col-md-6">
          <div className='text-muted mb-2 pb-1'>{t('COUNTRIES_LABEL')}</div>
          <Select
            required
            placeholder={t('COUNTRIES')}
            className="react-select-container mb-40"
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={channel.countries}
            onChange={handleChangeChannel('countries')}
            components={{Option: CustomOption}}
            options={Regions.map(ln => ({value: ln, label: ln}))}
            //@ts-ignore
            getOptionLabel={option => geos[option?.label?.toUpperCase()]?.name || option}
            isMulti
            noOptionsMessage={() => t('EMPTY_LIST')}
          />
        </div>
        <div className="col-12 col-md-6">
          <div className='text-muted mb-2 pb-1'>{t('GENRES_LABEL')}</div>
          <Select
            required={Boolean(categories?.length)}
            placeholder={t('GENRES')}
            className="react-select-container mb-40"
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={channel.categories}
            onChange={handleChangeChannel('categories')}
            components={{Option: CustomOption}}
            options={categories}
            getOptionValue={option => option.id + ''}
            getOptionLabel={option => option.name}
            isMulti
            noOptionsMessage={() => t('EMPTY_LIST')}
          />
        </div>
      </div>
      <Button type='submit' className='btn-primary btn-lg justify-content-between d-flex' style={{minWidth: 200}}
              loading={loading}>
        <span className='flex-grow-1 text-center'>{t('ADD_CHANNEL')}</span>
        <ReactSVG src={arrow} className='react-icon btn-primary-icon ms-0'/>
      </Button>
    </form>
  );
};

export default RegistrationForm;


const CustomOption = ({children, ...props}: any) => {
  return (
    <components.Option {...props}>
      <div className='react-select__option-content'>
        {children}
      </div>
    </components.Option>
  );
};