import React, {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import Header from "../../components/Header";
import Section_1 from "./Section1";
import Section_2 from "./Section2";
import Section_6 from "./Section_6";
import Section_4 from "./Section_4";
import Section_5 from "./Section_5";
import Footer from "../../components/Footer";
import {API} from "../../utils/api";
import {EFieldGroup, IDoc} from "../../utils/rest";
import {useNavigate} from "react-router-dom";
import Section3 from "./Section3";
import Section4 from "./Section4";

export interface ITerms {
  terms: IDoc,
  privacy: IDoc
}

interface Props {

}

const HomePage: FC<Props> = () => {
  const [terms, setTerms] = useState<ITerms>();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTerms()
  }, [])


  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  const fetchTerms = useCallback(async () => {
    try {
      const [privacy, terms] = await Promise.all([
        API.Docs.getByTag('reactrino.privacy', [EFieldGroup.DocContent]),
        API.Docs.getByTag('reactrino.terms', [EFieldGroup.DocContent]),
      ]);
      setTerms({privacy, terms})
    } catch (e) {

    }
  }, []);

  return (
    <>
      <Header terms={terms}/>
      <main >
        <div className="container" >
          <Section_1 onRegister={handleRegister}/>
          <Section_2 onRegister={handleRegister}/>
          <Section3 onRegister={handleRegister}/>
          <Section4 onRegister={handleRegister}/>
          {/*<Section_4/>*/}
          {/*<Section_5/>*/}
          {/*<Section_6  onRegister={handleRegister}/>*/}
        </div>
      </main>
      <Footer terms={terms}/>
    </>
  );
}

export default HomePage;
