import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import img from './assets/img.png';

interface Props {
  onRegister: () => void;
}

const Section4: FC<Props> = ({onRegister}) => {
  const {t} = useTranslation();
  return (
    <section className='section-4' id='section-4'>
      <div className="section-4-content">
        <h2 className='text-center' dangerouslySetInnerHTML={{__html: t('SECTION4_TITLE') || ''}}/>
        <div className='section-4-footer'>
          <div className='section-4-footer-text'>
            <h3 className='mb-0'>{t('MONETIZATION')}</h3>
            <p className='text-center mt-3 mb-40' dangerouslySetInnerHTML={{__html: t('SECTION4_TEXT') || ''}}/>
            <button className='btn btn-primary btn-lg' type='button' onClick={onRegister}>{t('CONNECT')}</button>
          </div>
          <div className='section-4-footer-img'>
            <img src={img} alt="img"/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;