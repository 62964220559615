import React, {FC} from 'react';
import './footer.css';
import logo from '../../assets/images/logo_dark.png';
import {privacy_href, support_href, terms_href} from "../../data/constants";
import {useTranslation} from "react-i18next";
import TermsLink from "../TermsLink";
import {ITerms} from "../../pages/HomePage/HomePage";

interface Props {
  terms?: ITerms
}

const Footer: FC<Props> = ({terms}) => {
  const {t} = useTranslation();
  return (
    <footer>
      <div className="container footer">
        <img src={logo} alt="logo" className='footer-logo'/>
        <div className='d-flex justify-content-between flex-grow-1  w-100'>
          <div className='d-flex flex-column flex-lg-row flex-grow-1 justify-content-around align-items-start align-items-lg-center'>
            <TermsLink type='privacy' doc={terms?.privacy} />
            <TermsLink type='terms' doc={terms?.terms}/>
            {/*<a href={privacy_href} target='_blank' className='text-14 text-decoration-underline'>{t('PRIVACY')}</a>*/}
            {/*<a href={terms_href} target='_blank' className='text-14 text-decoration-underline'>{t('TERMS')}</a>*/}
          </div>
          <a href={`mailto:${support_href}`} target='_blank' className='btn btn-outline-secondary'>
            <div>{t('CONTACT_US')}</div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;