import React, {FC, useLayoutEffect, useState} from 'react';
import ReactPlayer from "react-player";
import {ReactSVG} from "react-svg";
import pause_svg from "../../../../assets/images/icons/pause.svg";
import play_svg from "../../../../assets/images/icons/play.svg";
import expand from "../../../../assets/images/icons/expand.svg";
import collapse_svg from "../../../../assets/images/icons/collapse.svg";
import arrow_tr from "../../../../assets/images/icons/arrow_tr.svg";

interface Props {
  video: any;
  image: any;
  link: string;
  name: string
  onPlay: () => void;
  onExpand: () => void;
  playing?: boolean;
  collapse?: boolean;
}

const Section2Video: FC<Props> = ({video, onPlay, onExpand, playing, link, name, image, collapse}) => {
  const [isApple, setIsApple] = useState(false);

  useLayoutEffect(() => {
    const device = navigator.userAgent.toLowerCase();
    const apple = device.match(/iphone|ipad|ipod/);
    setIsApple(Boolean(apple));
  },[])

  const handleLinkClick = () => {
    if(playing) onPlay()
  }

  return (
    <div className={`section-2-item-video${playing ? ' active' : ''}${isApple ? ' apple' : ''}`}>
      <ReactPlayer
        url={video}
        // onPause={() => setActiveVideoIdx(null)}
        playing={playing}
        controls={false}
      />
      {/*<video src={item.video} />*/}
      <button type={"button"} className='btn section-2-item-play' onClick={onPlay}>
        <ReactSVG src={playing ? pause_svg : play_svg} className='react-icon'/>
      </button>
      <button type={"button"} className='btn btn-expand' onClick={onExpand}>
        <ReactSVG src={collapse ? collapse_svg : expand} className='react-icon'/>
      </button>
      <a href={link} target='_blank' className='section-2-item-channel' onClick={handleLinkClick}>
        <img src={image} alt="channel"/>
        <h4>{name}</h4>
        <ReactSVG src={arrow_tr} className='react-icon' />
      </a>
    </div>
  );
}

export default Section2Video;