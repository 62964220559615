


export enum ECountry {
  AU = "au",
  AT = "at",
  AZ = "az",
  AX = "ax",
  AL = "al",
  DZ = "dz",
  VI = "vi",
  AS = "as",
  AI = "ai",
  AO = "ao",
  AD = "ad",
  AQ = "aq",
  AG = "ag",
  AR = "ar",
  AM = "am",
  AW = "aw",
  AF = "af",
  BS = "bs",
  BD = "bd",
  BB = "bb",
  BH = "bh",
  BZ = "bz",
  BY = "by",
  BE = "be",
  BJ = "bj",
  BM = "bm",
  BG = "bg",
  BO = "bo",
  BQ = "bq",
  BA = "ba",
  BW = "bw",
  BR = "br",
  IO = "io",
  VG = "vg",
  BN = "bn",
  BF = "bf",
  BI = "bi",
  BT = "bt",
  VU = "vu",
  VA = "va",
  GB = "gb",
  HU = "hu",
  VE = "ve",
  UM = "um",
  TL = "tl",
  VN = "vn",
  GA = "ga",
  HT = "ht",
  GY = "gy",
  GM = "gm",
  GH = "gh",
  GP = "gp",
  GT = "gt",
  GF = "gf",
  GN = "gn",
  GW = "gw",
  DE = "de",
  GG = "gg",
  GI = "gi",
  HN = "hn",
  HK = "hk",
  GD = "gd",
  GL = "gl",
  GR = "gr",
  GE = "ge",
  GU = "gu",
  DK = "dk",
  JE = "je",
  DJ = "dj",
  DM = "dm",
  DO = "do",
  CD = "cd",
  EU = "eu",
  EG = "eg",
  ZM = "zm",
  EH = "eh",
  ZW = "zw",
  IL = "il",
  IN = "in",
  ID = "id",
  JO = "jo",
  IQ = "iq",
  IR = "ir",
  IE = "ie",
  IS = "is",
  ES = "es",
  IT = "it",
  YE = "ye",
  CV = "cv",
  KZ = "kz",
  KY = "ky",
  KH = "kh",
  CM = "cm",
  CA = "ca",
  QA = "qa",
  KE = "ke",
  CY = "cy",
  KG = "kg",
  KI = "ki",
  TW = "tw",
  KP = "kp",
  CN = "cn",
  CC = "cc",
  CO = "co",
  KM = "km",
  CR = "cr",
  CI = "ci",
  CU = "cu",
  KW = "kw",
  CW = "cw",
  LA = "la",
  LV = "lv",
  LS = "ls",
  LR = "lr",
  LB = "lb",
  LY = "ly",
  LT = "lt",
  LI = "li",
  LU = "lu",
  MU = "mu",
  MR = "mr",
  MG = "mg",
  YT = "yt",
  MO = "mo",
  MK = "mk",
  MW = "mw",
  MY = "my",
  ML = "ml",
  MV = "mv",
  MT = "mt",
  MA = "ma",
  MQ = "mq",
  MH = "mh",
  MX = "mx",
  FM = "fm",
  MZ = "mz",
  MD = "md",
  MC = "mc",
  MN = "mn",
  MS = "ms",
  MM = "mm",
  NA = "na",
  NR = "nr",
  NP = "np",
  NE = "ne",
  NG = "ng",
  NL = "nl",
  NI = "ni",
  NU = "nu",
  NZ = "nz",
  NC = "nc",
  NO = "no",
  AE = "ae",
  OM = "om",
  BV = "bv",
  IM = "im",
  CK = "ck",
  NF = "nf",
  CX = "cx",
  PN = "pn",
  SH = "sh",
  PK = "pk",
  PW = "pw",
  PS = "ps",
  PA = "pa",
  PG = "pg",
  PY = "py",
  PE = "pe",
  PL = "pl",
  PT = "pt",
  PR = "pr",
  CG = "cg",
  KR = "kr",
  RE = "re",
  RU = "ru",
  RW = "rw",
  RO = "ro",
  SV = "sv",
  WS = "ws",
  SM = "sm",
  ST = "st",
  SA = "sa",
  SZ = "sz",
  MP = "mp",
  SC = "sc",
  BL = "bl",
  MF = "mf",
  PM = "pm",
  SN = "sn",
  VC = "vc",
  KN = "kn",
  LC = "lc",
  RS = "rs",
  SG = "sg",
  SX = "sx",
  SY = "sy",
  SK = "sk",
  SI = "si",
  SB = "sb",
  SO = "so",
  SD = "sd",
  SR = "sr",
  US = "us",
  SL = "sl",
  TJ = "tj",
  TH = "th",
  TZ = "tz",
  TC = "tc",
  TG = "tg",
  TK = "tk",
  TO = "to",
  TT = "tt",
  TV = "tv",
  TN = "tn",
  TM = "tm",
  TR = "tr",
  UG = "ug",
  UZ = "uz",
  UA = "ua",
  WF = "wf",
  UY = "uy",
  FO = "fo",
  FJ = "fj",
  PH = "ph",
  FI = "fi",
  FK = "fk",
  FR = "fr",
  PF = "pf",
  TF = "tf",
  HM = "hm",
  HR = "hr",
  CF = "cf",
  TD = "td",
  ME = "me",
  CZ = "cz",
  CL = "cl",
  CH = "ch",
  SE = "se",
  SJ = "sj",
  LK = "lk",
  EC = "ec",
  GQ = "gq",
  ER = "er",
  EE = "ee",
  ET = "et",
  ZA = "za",
  GS = "gs",
  SS = "ss",
  JM = "jm",
  JP = "jp",
}

export const Regions: ECountry[] = [ECountry.AU, ECountry.AT, ECountry.AZ, ECountry.AL, ECountry.DZ, ECountry.AR, ECountry.AM, ECountry.BD, ECountry.BH, ECountry.BY, ECountry.BE, ECountry.BG, ECountry.BO, ECountry.BA, ECountry.BR, ECountry.GB, ECountry.HU, ECountry.VE, ECountry.VN, ECountry.GH, ECountry.GT, ECountry.DE, ECountry.HN, ECountry.HK, ECountry.GR, ECountry.GE, ECountry.DK, ECountry.DO, ECountry.EG, ECountry.ZW, ECountry.IL, ECountry.IN, ECountry.ID, ECountry.JO, ECountry.IQ, ECountry.IE, ECountry.IS, ECountry.ES, ECountry.IT, ECountry.YE, ECountry.KZ, ECountry.KH, ECountry.CA, ECountry.QA, ECountry.KE, ECountry.CY, ECountry.TW, ECountry.CO, ECountry.CR, ECountry.KW, ECountry.LA, ECountry.LV, ECountry.LB, ECountry.LY, ECountry.LT, ECountry.LI, ECountry.LU, ECountry.MK, ECountry.MY, ECountry.MT, ECountry.MA, ECountry.MX, ECountry.MD, ECountry.MN, ECountry.NP, ECountry.NG, ECountry.NL, ECountry.NI, ECountry.NZ, ECountry.NO, ECountry.AE, ECountry.OM, ECountry.PK, ECountry.PA, ECountry.PG, ECountry.PY, ECountry.PE, ECountry.PL, ECountry.PT, ECountry.PR, ECountry.KR, ECountry.RU, ECountry.RO, ECountry.SV, ECountry.SA, ECountry.SN, ECountry.RS, ECountry.SG, ECountry.SK, ECountry.SI, ECountry.US, ECountry.TH, ECountry.TZ, ECountry.TN, ECountry.TR, ECountry.UG, ECountry.UA, ECountry.UY, ECountry.PH, ECountry.FI, ECountry.FR, ECountry.HR, ECountry.ME, ECountry.CZ, ECountry.CL, ECountry.CH, ECountry.SE, ECountry.LK, ECountry.EC, ECountry.EE, ECountry.ZA, ECountry.JM, ECountry.JP];