import {toast as toastify} from 'react-toastify';
import {availableLanguages} from "../i18n";

const toastConfig = {
  position: 'top-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export function toast(text: string, type = 'error') {
  if (!text) return null;
  // @ts-ignore
  toastify[type](text, toastConfig);
  // @ts-ignore
  // toastify(Toast, {...toastConfig, data: {type, text}});
}

export const getLang = (ln?: string) => {
  //@ts-ignore
  if (!ln) ln = localStorage.getItem('ln') || (window?.navigator?.userLanguage || window.navigator.language).substring(0, 2);
  return availableLanguages.includes(ln!) ? ln : 'en';
}

