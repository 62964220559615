import React, {FC} from 'react';
import logo from "../assets/images/logo.png";

interface Props {
  className?: string;
}

const Logo: FC<Props> = ({ className}) => {
  return (
    <img src={logo} alt="logo" className={className || ''}/>
  );
}

export default Logo;