import React, { ButtonHTMLAttributes, FC } from 'react';
import Spinner from '../Spinner';

interface Props extends ButtonHTMLAttributes<any> {
  children: any;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
}

const Button: FC<Props> = ({ children, loading, disabled, className, ...props }) => {
  return (
    <button className={`btn${loading ? ' loading' : ''}${disabled ? ' disabled' : ''} ${className || ''}`} {...props}>
      {children}
      {loading ? <div className='btn-spinner'><Spinner  /></div> : null}
    </button>
  );
};

export default Button;
