import {useLayoutEffect, useState} from 'react';

function onEntry(entry: any) {
  entry?.forEach((change: any) => {
    const offset = (change?.boundingClientRect?.top - change?.boundingClientRect?.height - window.innerHeight + 100) < 0
    if (change.isIntersecting && offset) {
      change.target.classList.add('active');
    }
  });
}

const youtubeBtnBgPosition = () => {
  let options = {
    threshold: [0.5],
    // rootMargin: '120px',
  };
  let observer = new IntersectionObserver(onEntry, options);
  let elements: any = document.querySelectorAll('.section-3-item');
  for (let elm of elements) {
    observer.observe(elm);
  }

}


const clamp = (value: number) => Math.max(0, value);

// Check if number is between two values
const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

export default (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('');

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      youtubeBtnBgPosition();
      if (scroll - height > 0) return ids.at(-1);
      const position = ids
        .map((id) => {
          const element = document.getElementById(id);
          if (!element) return {id, top: -1, bottom: -1};
          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);
          return {id, top, bottom};
        })
        .find(({top, bottom}) => isBetween(scroll, top, bottom));
      setActiveId(position?.id || '');
    };
    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [ids, offset]);

  return activeId;
};
