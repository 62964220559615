import React, {FC} from 'react';

import './header.css'
import './header-media.css'

import {useTranslation} from "react-i18next";
import LangSelect from "./LangSelect";
import Logo from "../Logo";
import {login_href, support_href} from "../../data/constants";
import {Link} from "react-router-dom";

interface Props {
}

const HeaderSecondary: FC<Props> = () => {
  const {t} = useTranslation();


  return (
    <header>
      <div className="header container header-secondary">
        <div className='header-left'>
          <div className='d-flex align-items-center'>
            <Link to={'/'}>
              <Logo className='header-logo'/>
            </Link>
            <div className="header-separator d-none d-lg-block"/>
          </div>
        </div>
        <div className='header-right'>
          <a href={`mailto:${support_href}`} target='_blank'
             className='btn btn-outline-primary ms-2 order-1 order-xl-0'>
            <div>
              <span className='d-none d-sm-inline'>{t('WRITE_TO_SUPPORT')}</span>
              <span className='d-sm-none d-inline'>{t('SUPPORT')}</span>
            </div>
          </a>
          <a href={login_href} target={'_blank'} className='btn btn-outline-secondary'>
            {t('LOGIN')}
          </a>
          <LangSelect/>
        </div>
      </div>
    </header>
  );
}

export default HeaderSecondary;